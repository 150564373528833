import * as React from "react"
import logo_title from "../images/logo_title.png"
import home_img from "../images/home_img_for_web.png"
import pin from "../images/pin.png"
import time from "../images/time.png"

const Home = () => {
  return (
    <div className="home-section" id="home">
      <h1>
        <img
          className="logoTitle"
          src={logo_title}
          alt="European Beauty Atelier, Julita Kowalska Wasilewski"
        />
      </h1>
      <div className="homeContainer">
        <div className="column1">
          <img className="homeImg" src={home_img} alt="pretty woman" />
        </div>
        <div className="column2">
          <div className="book">
            <button className="bookButton" tabIndex={0}>
              <a
                target={`_blank`}
                className="bookButtonLink"
                href="https://europeanbeautyatelier20.booksy.com"
              >
                book an appointment
              </a>
            </button>
          </div>
          <div className="address">
            <p className="addressHeader">
              <img className="square" alt=""></img>Visit us here:
            </p>
            <br />
            <p className="addressText">
              <img className="icon-1" src={pin} alt="" />
              175 25th St, Ogden, UT
            </p>
            <p className="addressText" style={{ paddingTop: `10px` }}>
              Monday - Friday
            </p>
            <p className="addressText" style={{ paddingRight: `25px` }}>
              <img className="icon-2" src={time} alt="" />
              4pm - 6pm
            </p>
            <p className="addressText">Saturday 8am - 2pm</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
