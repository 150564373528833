import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Home from "./home"
import AboutUs from "./about"
import FaqPage from "./faq"
import Offer from "./offer"
import Contact from "./contact"

const socialLinks = [
  {
    text: "Instagram",
    url: "https://www.instagram.com/europeanbeautyatelier/",
  },
  {
    text: "Facebook",
    url: "https://www.facebook.com/europeanbeautyatelier/",
  },
  {
    text: "Google Maps",
    url: "https://goo.gl/maps/u7xbx6GZmcrsHMRx9",
  },
]

const IndexPage = () => (
  <Layout>
    <Seo title="" />
    <Home />
    <AboutUs />
    <Offer />
    <FaqPage />
    <Contact />
    {socialLinks.map((link, i) => (
      <React.Fragment key={link.url}>
        <a target={`_blank`} href={`${link.url}`}>
          {link.text}
        </a>
        {i !== socialLinks.length - 1 && <> · </>}
      </React.Fragment>
    ))}
  </Layout>
)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */

export default IndexPage

export const Head = () => <Seo title="" />
